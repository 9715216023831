@import 'variables';

// Direct styling of Angular material components

.mat-step-header .mat-step-icon {
  display: none;
}

.mat-horizontal-content-container {
  margin-top: 24px;
}

.mat-step-header {
  border-radius: 8px;
}

.mat-step-header .mat-step-label {
  font-size: 1rem;
  font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.mat-step-header:hover:not([aria-disabled]) {
  box-shadow: $box-shadow;
}

/* Begin spaced button radio group,
used with <mat-button-toggle-group class="material-radio-buttons"> */
.mat-button-toggle-group.material-radio-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;
  border: none;
  box-shadow: none;
  border-radius: 0;
  gap: 12px;

  .mat-button-toggle {
    border: none !important;
    flex-grow: 1;
    flex-shrink: 1;

    &:hover {
      background-color: $smoke-lighter;
      background-color: $white !important;

      .mat-button-toggle-button {
        border: 1px solid $grass-darker;
        background: $white !important;
      }
    }
  }

  .mat-button-toggle-checked {
    background-color: $white;

    .mat-button-toggle-button {
      border: 1px solid $grass-darker;
      background-color: $white !important;
    }
  }

  .mat-button-toggle-button {
    border: 1px solid $smoke;
    border-radius: 4px;
    color: $night;
  }
}
/* End spaced button radio group */

/* Material progress bar color override */
.mat-progress-bar-fill::after {
  background-color: $grass-darker !important;
}

.mat-progress-bar-buffer {
  background-color: $smoke-lighter !important;
}

.mat-progress-bar {
  border-radius: 2px;
}
/* End Material progress bar color override */

/* Begin Material expansion panel */
.mat-accordion {
  .mat-expansion-indicator::after {
    border-color: $night;
  }

  .mat-expansion-panel:hover {
    .mat-expansion-indicator::after {
      border-color: $raspberry-darker;
    }
  }
}
/* End Material expansion panel */

/* Max height for menu content for ngx-mat-intl-tel-input */
.mat-menu-content:has(.country-list-button) {
  max-height: 50vh;
  overflow: scroll;
}

/* Override the color of the outline of invalid mat-form-fields - we dont want to show red */
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: $lemon-darker !important;
}
