main.mobile {
  padding: 8px 16px !important;
  margin-left: 0 !important;
  margin-top: 60px;
}

.mobile-open-sidebar {
  position: fixed;
  height: 24px;
  width: 24px;
  top: 16px;
  right: 16px;
  z-index: 999;
  cursor: pointer;
}

.mobile-sidebar {
  background: #fff;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 32px;
  z-index: 999;
}

.mobile-sidebar-reduced-padding {
  padding: 0px !important;

  .rules__close-btn {
    padding: 16px;
    padding-bottom: 0;
  }
}

.mobile-backdrop {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}

.mobile {
  h2 {
    margin-bottom: 8px;
  }

  .sticky {
    position: static !important;
  }

  .mobile__event-short {
    font-weight: normal !important;
    font-size: 14px;
    font-family: 'Cera';
  }

  .button__add-new {
    width: 200px !important;
    float: right;
  }

  .pagination__container {
    margin-right: 16px;
  }

  .raspberry-dot {
    left: 4px !important;
  }

  .helptext-wrapper-type-content {
    .notification__description {
      margin-top: 0px !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  body.has__global-notifications {
    #main-app-area {
      padding-top: 0px !important;
    }
  }

  /* mobile nav tab related, but has to be placed here due to the specificity of the selector */
  body.has__global-notifications.mobile-nav-tabs-app #main-app-area {
    padding-top: 32px !important;
  }

  body.has__global-notifications main {
    margin-bottom: 80px !important;
  }

  body.has__global-notifications .mat-drawer-container {
    margin-top: 0px !important;
  }

  // We want to hide the launcher on all mobile screens
  // It is not possible to do this in Intercom settings
  .intercom-launcher {
    display: none !important;
  }

  .mobile__hidden {
    display: none !important;
  }

  .mobile__visible {
    display: block !important;
  }

  .mobile__visible-flex {
    display: flex !important;
  }

  .mat-dialog-container.ng-trigger {
    padding: 20px !important;
  }

  .on-hover {
    opacity: 1 !important;
  }

  .notification__global {
    top: unset !important;
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
    overflow-y: hidden;
    height: 44px !important;
    padding: 6px 12px !important;
  }

  .notification__global .notification__description {
    display: none !important;
  }

  .notification__wrapper {
    margin-bottom: 0 !important;
  }
}

.mobile-separator {
  display: none;
}

.mobile-nav-tabs-app {
  /** GENERAL */
  .mobile {
    h2 {
      margin-bottom: 0px;
    }
  }

  main.mobile {
    padding: 16px !important;
    margin-left: 0 !important;
    padding-bottom: calc(70px + env(safe-area-inset-bottom, 0.5rem)) !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
  }

  .mobile-open-sidebar {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 30px;
    right: 16px;
    z-index: 999;
    cursor: pointer;
  }

  /** END OF GENERAL */

  /** sbnb-dialog-add-notification */
  sbnb-dialog-add-notification {
    .newrule__body {
      flex-direction: column !important;
    }
  }

  /** END OF sbnb-dialog-add-notification */

  /** sbnb-home */
  sbnb-home {
    h2 {
      margin: 0 0 20px 0;

      @media #{$mobile} {
        margin: 20px 0;
      }
    }

    h3 {
      margin: 20px 0;
    }
  }

  /** END OF sbnb-home */

  /** sbnb-inbox */
  sbnb-inbox {
    .mobile {
      h2 {
        text-align: center;
      }
    }
  }

  /** END OF sbnb-inbox */

  /** sbnb-list-taxes */

  sbnb-list-taxes {
    h2 {
      margin-bottom: 0px !important;
    }

    .button__add-new-text__mobile {
      display: none;
    }

    .button__add-new-text__desktop {
      display: block;
    }
  }

  /** END OF sbnb-list-taxes */

  /** sbnb-logs */
  sbnb-logs {
    h2 {
      margin-bottom: 0px;
    }
  }

  /** END OF sbnb-logs */

  /** sbnb-notifications */
  sbnb-notifications {
    h2 {
      margin-bottom: 0px;
    }
  }

  /** END of sbnb-notifications */

  /** operations-notifications */
  .operations-notifications {
    h2 {
      margin-bottom: 0px;
    }
  }

  /** END OF operations-notifications */

  /** sbnb-settings-subnav */
  .settings-subnav {
    .mobile-separator {
      display: none;
    }

    h4 {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center flex-start;
      align-items: center;
    }
  }

  /** END OF sbnb-settings-subnav */

  /** sbnb-team */
  sbnb-team {
    h2 {
      margin-bottom: 0px;
    }
  }

  /** END OF sbnb-team */

  /** sbnb-toast-container */
  .sbnb-toast-container {
    bottom: 1.5rem;
  }

  /** END OF sbnb-toast-container */

  @media #{$desktop} {
    /** sbnb-back-button */
    sbnb-back-button {
      display: block !important;
    }

    /** END OF sbnb-back-button */

    /** sbnb-dialog-add-notification */
    sbnb-dialog-add-notification {
      .subtitle {
        max-width: 50%;
      }

      .newrule__body {
        flex-direction: row !important;
      }
    }

    /** END OF sbnb-dialog-add-notification */

    /** sbnb-logs */
    sbnb-logs {
      h2 {
        margin-bottom: 20px;
      }
    }

    /** END OF sbnb-logs */

    /** sbnb-notifications */
    sbnb-notifications {
      h2 {
        margin-bottom: 20px;
      }
    }

    /** END OF sbnb-notifications */

    /** sbnb-team */
    sbnb-team {
      h2 {
        margin-bottom: 20px;
      }
    }

    /** END OF sbnb-team */
  }

  @media #{$mobile} {
    /** GENERAL */
    mat-drawer,
    .mobile-sidebar,
    .prop-cal-sidebar {
      padding-bottom: 6rem;
    }

    .mobile-separator {
      display: block;
    }

    .notification__global {
      top: 0 !important;
      // bottom: 0 !important;
      width: 100% !important;
      left: 0 !important;
      overflow-y: hidden;
      height: 44px !important;
      padding: 6px 12px !important;
    }

    // attach position relative to all components that have sbnb-mobile-sidebar

    &.has__global-notifications .mobile-open-sidebar {
      top: 62px !important;
    }

    #modal-fs {
      z-index: 1000 !important;
    }

    .cdk-overlay-container {
      z-index: 1001 !important;
    }

    /** END OF GENERAL */

    /** sbnb-connect-flow-template */
    .flow-template {
      padding-bottom: 60px !important;
    }

    /** END OF sbnb-connect-flow-template */

    /** sbnb-direct-subnav */
    sbnb-direct-subnav {
      .direct-subnav {
        position: relative;

        .mobile-separator {
          display: block;
        }

        img,
        svg {
          width: 22px;
          height: 22px;
        }

        a,
        h4,
        .subnav-item {
          width: 100%;
          flex-direction: row;
          box-sizing: border-box;
          display: flex;
          place-content: center flex-start;
          align-items: center;
          max-width: 500px;
          margin: auto;
          padding: 12px 0;
          font-size: 18px;
          color: #5c576a;
          font-weight: 400 !important;
          font-family: 'Cera', 'Helvetica Neue', sans-serif;
          gap: 12px;
        }

        a:not(.no-details),
        h4:not(.no-details),
        .subnav-item:not(.no-details) {
          &::after {
            content: ' ';
            background-image: url('/assets/iconography/right-arrow.svg');
            display: block;
            position: absolute;
            background-size: 16px 16px;
            right: 0;
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
          }
        }

        .no-details {
          // margin-left: 34px;
          pointer-events: none;
        }

        .subnav-items {
          margin-left: 34px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    /** END OF sbnb-direct-subnav */

    /** sbnb-devices-subnav */
    .device-subnav {
      position: relative;

      .mobile-separator {
        display: block;
      }

      img,
      svg {
        width: 22px;
        height: 22px;
      }

      a,
      h4,
      .subnav-item {
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center flex-start;
        align-items: center;
        max-width: 500px;
        margin: auto;
        padding: 12px 0;
        font-size: 18px;
        color: #5c576a;
        font-weight: 400 !important;
        font-family: 'Cera', 'Helvetica Neue', sans-serif;
        gap: 12px;
      }

      a:not(.no-details),
      h4:not(.no-details),
      .subnav-item:not(.no-details) {
        &::after {
          content: ' ';
          background-image: url('/assets/iconography/right-arrow.svg');
          display: block;
          position: absolute;
          background-size: 16px 16px;
          right: 0;
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
        }
      }

      .no-details {
        // margin-left: 34px;
        pointer-events: none;
      }

      .subnav-items {
        margin-left: 34px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    /** END OF sbnb-devices-subnav */

    /** sbnb-gx-subnav */

    .gx-subnav {
      img,
      svg {
        width: 22px;
        height: 22px;
      }

      a,
      h4 {
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center flex-start;
        align-items: center;
        max-width: 500px;
        margin: auto;
        padding: 12px 0;
        font-size: 18px;
        color: #5c576a;
        font-weight: 400 !important;
        font-family: 'Cera', 'Helvetica Neue', sans-serif;
        padding-right: 24px;
      }

      a:not(.no-details),
      h4:not(.no-details) {
        &::after {
          content: ' ';
          background-image: url('/assets/iconography/right-arrow.svg');
          display: block;
          position: absolute;
          background-size: 16px 16px;
          right: 0;
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
        }
      }

      .no-details {
        pointer-events: none;
      }

      .log__subnav {
        margin-left: 32px;

        a {
          margin-bottom: 0 !important;
          padding-left: 0 !important;
          justify-content: space-between;
        }
      }

      .rental-agreements {
        justify-content: space-between;
      }

      .nav__subnav {
        div {
          margin-bottom: 0 !important;
          margin-left: 32px !important;
        }
      }
    }

    /** END OF sbnb-gx-subnav */

    /** sbnb-multi-calendar */

    sbnb-multi-calendar {
      #calendar {
        height: calc(100dvh - 80px) !important;
      }
    }

    /** END OF sbnb-multi-calendar */

    /** sbnb-list-taxes */
    sbnb-list-taxes {
      .button__add-new-text__desktop {
        display: none;
      }

      .button__add-new-text__mobile {
        display: block;
      }

      .mobile {
        .button__add-new {
          width: auto !important;
          font-size: 22px !important;
        }

        .button__add-new-container {
          position: absolute;
          right: 0;
        }
      }

      h2 {
        text-align: center;
      }
    }

    /** END OF sbnb-list-taxes */

    /** sbnb-property-calendar */
    sbnb-property-calendar {
      .property-loader {
        bottom: 70px;
      }
    }

    /** END OF sbnb-property-calendar */

    /** sbnb-operations-subnav */
    .operations-subnav {
      img,
      svg {
        width: 22px;
        height: 22px;
      }

      a,
      h4 {
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center flex-start;
        align-items: center;
        max-width: 500px;
        margin: auto;
        padding: 12px 0;
        font-size: 18px;
        color: #5c576a;
        font-weight: 400 !important;
        font-family: 'Cera', 'Helvetica Neue', sans-serif;
      }

      a:not(.no-details),
      h4:not(.no-details) {
        &::after {
          content: ' ';
          background-image: url('/assets/iconography/right-arrow.svg');
          display: block;
          position: absolute;
          background-size: 16px 16px;
          right: 0;
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
        }
      }

      .no-details {
        pointer-events: none;
      }
    }

    /** operations-notifications */
    .operations-notifications {
      h2 {
        margin-bottom: 20px;
      }
    }

    /** END OF operations-notifications */

    /** sbnb-settings-subnav */
    .settings-subnav {
      position: relative;

      .mobile-separator {
        display: block;
      }

      img,
      svg {
        width: 22px;
        height: 22px;
        margin-right: 0px;
      }

      a,
      h4 {
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex !important;
        place-content: center flex-start;
        align-items: center;
        max-width: 500px;
        margin: auto;
        padding: 12px 0;
        font-size: 18px;
        color: #5c576a;
        font-weight: 400 !important;
        font-family: 'Cera', 'Helvetica Neue', sans-serif;
      }

      a:not(.no-details),
      h4:not(.no-details) {
        &::after {
          content: ' ';
          background-image: url('/assets/iconography/right-arrow.svg');
          display: block;
          position: absolute;
          background-size: 16px 16px;
          right: 0;
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
        }
      }

      .no-details {
        // margin-left: 34px;
        pointer-events: none;
      }

      .subnav__container {
        margin-left: 34px;
        margin-bottom: 0px;

        a {
          margin: 0 !important;
        }
      }
    }

    /** saved-segments */
    .mobile-menu-saved-segments {
      a,
      h4 {
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex !important;
        place-content: center flex-start;
        align-items: center;
        max-width: 500px;
        margin: auto;
        padding: 12px 0;
        font-size: 18px;
        color: #5c576a;
        font-weight: 400 !important;
        font-family: 'Cera', 'Helvetica Neue', sans-serif;
        gap: 12px;
      }

      .nav__subnav {
        div {
          margin-bottom: 0 !important;
        }
      }
    }

    /** END OF saved-segments */

    /** sbnb-toast-container */
    .sbnb-toast-container {
      bottom: 3.5rem !important;
    }

    /** END OF sbnb-toast-container */

    /** thread component */
    .thread__container main.mobile {
      padding: 0 !important;
      margin: 0 !important;
      border-left: 1px solid #d0cdd4;
      border-right: 1px solid #d0cdd4;
      min-height: 100%;
    }

    /** END OF thread component */
  }
}
