@use '@angular/material' as mat;

@import 'variables';
@import 'mobile.scss';
@import 'material.scss';
@import 'metrics.scss';
@import 'tailwind-partial-preflight.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cera';
  src: url('/assets/fonts/ttnorms/tt_norms_regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Cera Headings';
  src: url('/assets/fonts/ttnorms/tt_norms_medium-webfont.woff2') format('woff2');
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Cera, Helvetica Neue, sans-serif',
);

@include mat.core();
@include mat.legacy-core();
@include mat.typography-hierarchy($custom-typography);

$smartbnb-front-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
      warn: mat.define-palette(mat.$red-palette),
    ),
    typography: $custom-typography,
    density: 0,
  )
);

@include mat.all-component-themes($smartbnb-front-theme);
@include mat.all-legacy-component-themes($smartbnb-front-theme);

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0;
}

main {
  padding: 80px 80px 16px 80px;
  margin-bottom: 160px;
  min-height: 100vh;

  &.subnav__exists {
    padding: 40px;
  }

  &.no__padding {
    padding: 0;
    margin-left: 0;
  }

  &.minimal__padding {
    margin-left: 80px;
    padding: 40px;
  }
}

html,
body {
  height: 100%;
  background: $body-bg;
  color: $primary-text-color;
  position: relative;
}

body {
  margin: 0;
  font-family: 'Cera', 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
  color: $text-headings;
  font-weight: normal;
  margin: 0 0;
}

h1 {
  font-size: 2em;
  margin-top: 0;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 36px;
}

h3 {
  font-size: 1.25em;
  color: $grape-darker;
}

h4 {
  font-size: 1em;
  color: $night-darker;
}

h5 {
  font-size: 0.75em;
  text-transform: uppercase;
  color: $text-headings-light;
  letter-spacing: 1px;
}

p,
textarea {
  line-height: 28px !important;
}

.text__xlarge {
  font-size: 1.25em;
}

.text__large {
  font-size: 1.125em;
}

.text__medium {
  font-size: 1em;
}

.text__small {
  font-size: 0.875em;
}

.text__xsmall {
  font-size: 0.75em;
}

.text__xxsmall {
  font-size: 0.625em;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus {
  outline: none;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-heavy {
  box-shadow: $box-shadow-heavy;
}

.box-shadow-heavier {
  box-shadow: $box-shadow-heavier;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-app-background {
  background: transparent !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.nowrap {
  white-space: nowrap;
}

// Material Ripple color
.mat-ripple-element {
  background-color: $raspberry !important;
}

// Allows labels to wrap properly within checkboxes
.mat-checkbox-label {
  white-space: normal;
}

// Disabled inputs, buttons, etc
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled],
.mat-icon-button[disabled] {
  background: $smoke-lighter;
}

// Fixed width fields
.mat-form-field.fw,
.mat-flat-button.fw {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

// A loading spinner inside a material button
.button__spinner {
  position: absolute !important;
  top: 9px;
  left: calc(50% - 15px);
}

.button__small .button__spinner,
.button__icon-small .button__spinner {
  left: calc(50% - 8px);
}

// Loading indicator
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $raspberry !important;
}

.mat-progress-spinner.muted circle,
.mat-spinner.muted circle {
  stroke: $night-lighter !important;
}

// Border colours on matInputs
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $smoke;
}

// Padding on matInputs
.mat-form-field-flex {
  padding: 0 16px !important;
}

// Border colours on focused matInputs
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $grass-darker !important;
}

// Label colours on matInputs
.mat-form-field-label {
  color: $night-lighter;
}

// Label colours on focused matInputs
.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: $grass-darker;
}

// matInput value color
.mat-input-element {
  color: $night-darker !important;
}

.mat-input-element:disabled {
  color: $night-lighter !important;
}

// Colour of invalid inputs
.mat-form-field.ng-invalid.ng-touched .mat-form-field-underline {
  background-color: $error;
}

/* Sets the label to red if dirty and invalid */
.mat-form-field.ng-invalid.ng-touched .mat-form-field-label {
  color: $night;
}

.mat-error {
  color: $error;
  font-size: 0.875rem;
}

.mat-form-field.ng-invalid.ng-touched .mat-form-field-outline {
  background: $error-bg;
  border-radius: 4px;
}

.mat-form-field.ng-invalid.ng-touched .mat-input-element {
  color: black !important;
}

.mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-start {
  border: 2px solid $error !important;
  border-right-style: hidden !important;
}

.mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-gap {
  border: 2px solid $error !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
}

.mat-form-field.ng-invalid.ng-touched .mat-form-field-outline-end {
  border: 2px solid $error !important;
  border-left-style: hidden !important;
}

.mat-form-field.ng-invalid.ng-touched.mat-focused .mat-form-field-outline-gap {
  border-top-style: hidden !important;
}

// disabled matInput background color
.mat-form-field-disabled .mat-form-field-outline {
  background: $smoke-lighter;
  border-radius: 5px;
}

// Move prefix icons to reduce their padding
.mat-form-field-prefix {
  left: -6px;
}

/* Begin 48px fixed height matInput voodoo */
.mat-form-field-flex {
  height: 48px;
}

.mat-form-field-infix {
  padding: 0 !important;
  padding-top: 5px !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 22px !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.2em) scale(0.75);
}

/* End 48px fixed height matInput voodoo */

// Remove indendation from matInput errors
.mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.input__mini {
  font-size: 14px;

  input,
  .mat-select-value {
    height: 17px;
  }

  mat-icon {
    font-size: 16px !important;
    position: relative;
  }

  /* Begin 32px fixed height mini matInput voodoo */
  .mat-form-field-flex {
    height: 32px;
    margin-top: -0.5em !important;
  }

  .mat-form-field-outline {
    height: 32px;
  }

  .mat-form-field-infix {
    padding: 0 !important;
    padding-top: 0px !important;
  }

  .mat-form-field-label {
    top: 15px !important;
  }

  /* End 32px fixed height mini matInput voodoo */
}

/* Additional 32px fixed height mini matInput voodoo - can't nest this */
.input__mini.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-0.8em) scale(0.75);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

// Clearable matInput clear button
.button__clear {
  position: relative;
  top: -5px;
}

// Hints on inputs
.mat-hint {
  color: $night-lighter;
}

// Cera font looks a bit odd without spacing in password fields
input[type='password'] {
  letter-spacing: 4px;
}

input[type='password']::placeholder {
  letter-spacing: normal;
}

// Vertically Centre the select dropdown arrow
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}

.mat-option-text {
  font-size: 14px;
}

// Chevron arrow on select dropdowns
.mat-select-arrow {
  border-left: 0 !important;
  border-right: 0 !important;
  position: relative;
  display: block;
  top: -5px;
  left: -5px;
  height: 12px;
  /*height should be double border below */

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    border: 6px solid transparent;
  }

  &:before {
    top: 0;
    border-top-color: $night;
  }

  &:after {
    top: -3px;
    /*adjust thickness*/
    border-top-color: #fff;
    /*Match background colour*/
  }
}

.mat-tab-label {
  padding: 0 !important;
  min-width: 120px !important;
  font-size: 16px !important;
}

.mat-tab-label-active {
  opacity: 1 !important;

  .mat-tab-label-content {
    color: $night !important;
  }
}

.mat-ink-bar {
  background-color: $raspberry-darker !important;
}

/* Begin Checkbox styling */
.mat-checkbox {
  padding: 8px;
  border-radius: 4px;
  /* padding-bottom: 12px; */
}

.mat-checkbox:hover {
  background: $smoke-lighter;
}

.mat-checkbox-disabled.mat-checkbox:hover {
  background: none;
}

.mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}

.mat-checkbox-frame {
  border-radius: 4px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $grass-darker;
}

.mat-checkbox-frame {
  border: 1px solid $smoke-darker !important;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border: 1px solid $smoke !important;
  z-index: 9 !important;
}

// Disabled checkbox color
.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-background,
.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: $smoke-lighter;
}

.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: $grass-lighter;
}

/* End Checkbox styling */

/* Radio Styling */
.mat-radio-button {
  padding: 8px;
  border-radius: 4px;

  label {
    width: 100%;
  }

  &:not(.mat-radio-disabled):hover {
    background: $smoke-lighter;
  }
}

.mat-radio-label {
  margin-right: 16px;
}

.mat-radio-outer-circle {
  border: 1px solid $smoke !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  background-color: $grass-darker !important;
}

.mat-radio-checked .mat-radio-outer-circle {
  border: 1px solid $grass-darker !important;
}

.mat-radio-disabled .mat-radio-outer-circle {
  background: $smoke-lighter;
}

.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  background: $grass-lighter;
}

.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #fff !important;
}

/* End Radio Styling */

/* Begin Toggle Styling */
.mat-slide-toggle {
  padding: 8px 0;
  height: auto !important;
}

.mat-slide-toggle-bar {
  border: 2px solid $smoke;
  width: 32px !important;
  height: 20px !important;
  background-color: $smoke !important;
  border-radius: 12px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0px !important;
}

.mat-slide-toggle-thumb {
  height: 16px !important;
  width: 16px !important;
  box-shadow: none !important;
}

.mat-slide-toggle-ripple {
  display: none;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: $white;
  }

  .mat-slide-toggle-thumb-container {
    transform: translate3d(12px, 0, 0) !important;
  }

  .mat-slide-toggle-bar {
    border-color: $grass-darker;
    background-color: $grass-darker !important;
  }
}

.mat-slide-toggle.mat-disabled:not(.mat-checked) {
  .mat-slide-toggle-thumb {
    background-color: $smoke-darker !important;
  }
  .mat-slide-toggle-bar {
    background-color: #fff !important;
    border-color: $smoke-darker;
  }
}

/* End Toggle Styling */

/* Begin Button Styling */
.mat-flat-button:not(.button__small) {
  white-space: normal !important;
  min-height: 48px;
}

.mat-flat-button {
  background-color: $raspberry-darker;
  color: #fff;
  line-height: 19px !important;
  padding: 0 24px !important;
  font-size: 16px;
  font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;

  &:hover {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .mat-button-ripple {
    display: none;
  }

  .mat-icon {
    margin-left: -8px;
  }
}

// Small Buttons
.mat-flat-button.button__small {
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  padding: 0 12px !important;

  .mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
    margin-left: -4px;
  }
}

.button__secondary {
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid $smoke !important;
  color: $night;

  &:hover {
    box-shadow: none !important;
    background-color: $smoke-lighter;
  }

  &:active {
    border: 1px solid $smoke-darker !important;
  }

  .mat-icon {
    color: $night-lighter;
  }
}

.button__text {
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid transparent !important;
  color: $grass-darker;
  border-radius: 4px;
  padding: 2px 6px;

  &:hover {
    box-shadow: none !important;
    background-color: $smoke-lighter;
    border: 1px solid $smoke-darker !important;
  }

  &:active {
    border: 1px solid transparent !important;
    background-color: $grass-lighter;
  }

  .mat-icon {
    color: $night-lighter;
  }
}

.button__text-secondary {
  background-color: #fff;
  box-shadow: none !important;
  border: none !important;
  color: $night;
  font-weight: normal !important;
  font-family: 'Cera';
  font-size: 12px !important;

  &:hover {
    box-shadow: none !important;
    background: $smoke-lighter;
  }
}

.button__icon {
  background-color: #fff;
  box-shadow: none !important;
  padding: 0 !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 4px !important;

  &:hover {
    box-shadow: none !important;
    background-color: $smoke-lighter;
  }

  &:active {
    background-color: $raspberry-lighter;
  }

  .mat-icon {
    color: $night-lighter;
  }
}

.button__icon-small {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 4px !important;

  .mat-icon {
    font-size: 16px;
    color: $raspberry-darker;
  }

  &[disabled] .mat-icon {
    color: $smoke;
  }
}

/* End Button Styling */

/* Begin Chips / Pills */
mat-chip {
  background-color: $raspberry-lighter !important;
  color: $night !important;
  padding: 16px !important;

  mat-icon {
    font-size: 14px !important;
    padding-top: 8px !important;
    width: 12px !important;
    margin-right: 8px !important;
    margin-left: -4px !important;
  }
}

.chip__coloured {
  background-color: $raspberry-darker !important;
  color: #fff !important;
}

/* End Chips / Pills */

.mat-tooltip {
  background: #fff;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 0.8em;
  color: $night !important;
  opacity: 0.95;
  border: 1px solid $smoke;
  box-shadow:
    1px 2px 2px hsla(210, 2%, 83%, 0.333),
    2px 4px 4px hsla(210, 2%, 83%, 0.333),
    3px 6px 6px hsla(210, 2%, 83%, 0.333) !important;
  white-space: pre-line !important;
  line-height: 24px;
}

.tooltip-dark {
  background: $grape-darker !important;
  color: #fff !important;
}

/* Begin Button Toggle Sizing */
.mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-button-toggle-label-content {
  color: $night-darker !important;
}

.mat-button-toggle-checked .mat-button-toggle-button {
  background: $smoke-lighter !important;
}

.page__active .mat-button-toggle-label-content {
  color: $raspberry-darker !important;
}

.paginator__prevnext {
  .mat-button-toggle-label-content {
    color: $raspberry-darker !important;
  }
}
/* End Button Toggle Sizing */

.tooltip-large {
  @media only screen and (min-width: 600px) {
    width: 500px;
    max-width: unset !important;
  }
}

/* Begin Pagination */
.pagination {
  margin-top: 40px;
}

.paginator .mat-button-toggle-checked .mat-button-toggle-button {
  background: #fff !important;
}

.page__active {
  background: $smoke-lighter !important;
}

/* End Pagination */

// Used to pierce through to mat components and reduce padding
.deep-16px-padding .mat-form-field-wrapper {
  padding: 0 !important;
  padding-bottom: 16px !important;
}

.mat-menu-item:not(.country-list-button) {
  height: 32px !important;
  line-height: 32px !important;
}

// Filter component menu styling, can't use encapsulated styles here as it breaks out of the component
.mat-menu-panel.filters__cdk-menu {
  width: 280px;

  .mat-menu-item span mat-icon {
    margin-right: 8px !important;
  }
}

.mat-menu-panel.filters__cdk-menu-new {
  width: 242px;
  overflow-x: hidden;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mat-menu-panel {
  min-height: 48px !important;
  border: 1px solid $smoke;
  box-shadow:
    1px 2px 2px hsla(210, 2%, 83%, 0.333),
    2px 4px 4px hsla(210, 2%, 83%, 0.333),
    3px 6px 6px hsla(210, 2%, 83%, 0.333) !important;
}

.filters__cdk-menu-new-inner {
  padding: 16px;

  .mat-checkbox-layout,
  .mat-checkbox-label {
    width: 100%;
  }
}

.noresize {
  resize: none;
}

.filters__cdk-menu-320 {
  width: 320px !important;
  max-width: 320px !important;
}

.filters__cdk-menu-480 {
  width: 480px !important;
  max-width: 480px !important;
}

.cdk-menu-190 {
  width: 190px !important;
  max-width: 190px !important;
}

.filters__cdk-menu-130 {
  width: 160px !important;
}

.condition__menu {
  width: 500px !important;
  max-width: 500px !important;
}

// Fixed height mat-menu for filters of type list
.filter__list-fixed-height {
  max-height: 300px !important;
  overflow-y: auto !important;
}

.filter__list-no-data {
  padding: 0 16px;
  line-height: 1.6;
  color: $night-lighter;
  display: block;
}

.filter__option-img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
  // top: 4px;
}

.filter__btn-done {
  text-align: center;
  color: $grass-darker;
  display: block;
  width: 100%;
  padding: 16px;
  padding-bottom: 8px;
  border-top: 1px solid $smoke;
  margin-top: 8px;
}

.property__btn-done {
  margin-top: 20px;
  position: relative;
  left: -16px;
  width: calc(100% + 32px);
}

.filters__input-list-search {
  background: url('/assets/images/mat-search-24px.svg') no-repeat scroll 16px 8px;
  background-size: 16px;
  height: 32px;
  box-sizing: border-box;
  padding: 16px;
  padding-left: 40px;
  padding-bottom: 24px;
  width: 100%;
  border: none;
  border-bottom: 1px solid $smoke;
  font-size: 14px;
}

.property__scheduleMenu {
  width: 300px !important;
  max-width: 300px !important;
  padding: 16px;
  padding-bottom: 0;

  mat-radio-group {
    margin-top: 20px;
  }
}

.mat-dialog-container {
  padding: 40px 32px !important;
}

.dialog__no-padding .mat-dialog-container {
  padding: 0 !important;
}

.table__no-items {
  padding: 16px;
  border: 1px solid $smoke-lighter;
}

.subnav__reduced-padding .subnav__wrapper {
  padding: 40px 16px !important;
}

.subnav__semi-reduced-padding .subnav__wrapper {
  padding: 40px 24px !important;
}

.nopadding .subnav__wrapper {
  padding: 0 !important;
}

.subnav__wrapper h2 {
  margin-top: 0;
}

.subnav__wrapper h4 {
  margin-bottom: 16px;
  cursor: pointer;
}

.subnav__wrapper {
  .active-link {
    svg path {
      stroke: $raspberry-darker !important;
    }
  }
}

.icon-placeholder {
  width: 18px;
  height: 18px;
  background: $smoke-lighter;
  border-radius: 50%;
}

.icon-placeholder-24 {
  width: 24px;
  height: 24px;
  background: $smoke-lighter;
  border-radius: 50%;
}

.icon-placeholder-56 {
  width: 56px;
  height: 56px;
  background: $smoke;
  border-radius: 50%;
}

.border-radius-50 {
  border-radius: 50%;
}

/* Begin Cards */

.card__container {
  box-shadow: 0 1px 3px rgba(38, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 20px;
}

.card {
  background: #fff;
  padding: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card__btn-footer {
  display: block;
  text-align: center;
  height: 48px;
  line-height: 48px;
  color: $raspberry-darker;
  border-top: 1px solid $smoke-lighter;
  font-size: 16px;
  font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
  cursor: pointer;

  a {
    color: $raspberry-darker !important;
  }
}

.card__collapsed-helper-text {
  font-size: 14px;
  color: $night-lighter;
  padding-left: 16px;
  font-family: 'Cera';
}

.card__collapsed-helper-text-newline {
  padding-left: 0 !important;
  padding-top: 6px;
  display: block;
}

/* End Cards */

.rules__container {
  border-bottom: 2px solid $smoke-lighter;
}

.rules__rule-container {
  border-top: 1px solid $smoke;
  height: 56px;
  padding: 9px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
    // sorts the overlapping box shadow
    position: relative;
  }
}

.rules__rule-header {
  font-size: 12px;
  color: $night-lighter;
  margin-bottom: 12px;
  padding: 0 9px;
}

.rule__title {
  font-weight: bold;
  cursor: pointer;
}

.webhook__container {
  margin-bottom: 16px;

  .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-form-field-disabled .mat-form-field-outline {
    background: $grass-lighter !important;
  }
}

.webhook__delete {
  color: $grass-darker;
  position: absolute;
  top: 5px;
  right: 0;
}

.rule__edit-title {
  width: 300px;

  .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }

  .rule__edit-close-btn {
    margin-right: 8px;
  }

  .button__small {
    position: relative;
    top: -7px;
    right: -7px;
  }
}

.rule__section-container {
  margin-top: 24px;
  padding: 24px 0;
  padding-bottom: 0px;
  border-top: 1px solid $smoke;

  &.rule__section-collapsed h3 {
    margin-bottom: 4px !important;
  }

  h3 {
    margin-bottom: 30px;
  }

  p {
    margin-top: 0;
  }
}

.rule__scope-box {
  border-radius: 4px;
  border: 1px solid $smoke;
  padding: 24px;
}

.rule__scope-header {
  margin-bottom: 16px;
}

.rules__scrollable {
  height: 280px;
  overflow-y: auto;
}

.property__img {
  width: 24px;
  height: 20px;
  border-radius: 4px;
}

.rule__section-collapsed {
  padding-bottom: 0;
}

.rules__intro-text {
  .notification__description {
    max-width: 60%;
  }

  padding: 40px;
  background: #fff;
  margin-bottom: 16px;
  border: 1px solid $smoke-darker;
  border-radius: 4px;
  background-image: url('/assets/images/notifications-intro-bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 230px;

  h2 {
    margin-bottom: 16px;
  }
}

.rules__intro-buttons {
  button {
    margin-right: 16px;
    margin-top: 16px;
  }

  a {
    margin-right: 16px;
    margin-top: 16px;
    line-height: 48px !important;
  }
}

// mat-tab-group overrides on the dashboard
.dashboard__tabs {
  margin-top: 16px;

  .mat-tab-label-content {
    font-size: 20px;
    font-weight: bold;
    color: #17141c !important;
  }

  .mat-tab-label {
    height: 60px !important;
    justify-content: left !important;
    padding: 0 !important;
    min-width: 0 !important;
    margin-right: 40px;
  }
}

.mobile .dashboard__tabs .mat-tab-label {
  margin-right: 16px !important;
}

/* Tribute Styles */
.tribute-container {
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 6px;
  min-width: 240px;

  li {
    padding: 8px 16px;
    background-color: #fff;
    font-size: 14px;

    &.highlight {
      background: #f9f9f9;
    }

    &:hover,
    &:focus,
    &:active {
      background: #f9f9f9 !important;
    }
  }
}

.message__customcode {
  background: $smoke-lighter;
  color: $raspberry-darker;
}

#rule__editor .mat-tab-body {
  padding: 24px;
}

.rules__filters-container {
  margin-bottom: 16px;
}

.not-clickable {
  cursor: default !important;
}

.clickable {
  cursor: pointer !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  // top: 0 !important;
}

.input__mini .mat-form-field-appearance-outline .mat-form-field-prefix,
.input__mini .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 3px !important;
}

.cera-heading {
  font-family: 'Cera Headings';
}

strong,
.strong {
  font-family: 'Cera Headings';
}

#rule__editor {
  // height: 340px;
  border-radius: 4px;
  border: 1px solid $smoke-darker;

  .mat-tab-body {
    padding: 12px;
  }

  .rule__textarea-editor {
    padding: 8px;
    min-height: 160px;
    width: 100%;
    border: none;
    resize: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 30px;

    // A little hacky, to solve a regex issue
    // Both regexes apply (short and custom codes) and both apply a span with a colour
    // We override the background to transparent on the short code style, so that it is
    // clear that its a custom code
    span span {
      background-color: transparent !important;
    }
  }
}

.rule__textarea-editor-fixed {
  // max-height: 260px !important;
  overflow-y: auto;
}

.rule__add-language {
  margin-top: 24px;
  display: block;

  a {
    cursor: pointer;
  }
}

.task__duration {
  background: $smoke-lighter;
  padding: 4px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(13, 39, 85, 0.15) !important;
}

.mat-drawer-container {
  background-color: inherit !important;
  color: inherit !important;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: $grass-darker !important;
  border: none !important;
}

.mat-slider-track-background {
  background: $smoke !important;
}

.lemon {
  color: $lemon;
}

.lemon-darker {
  color: $lemon-darker !important;
}

.rose {
  color: $rose !important;
}

.rose-darker {
  color: $rose-darker;
}

.smoke-lighter {
  color: $smoke-lighter;
}

.smoke-darker {
  color: $smoke-darker;
}

.smoke {
  color: $smoke;
}

.grass {
  color: $grass;
}

.grass-darker {
  color: $grass-darker !important;
}

.ocean {
  color: $raspberry-darker;
}

.grape {
  color: $grape;
}

.white {
  color: white;
}

.night-lighter {
  color: $night-lighter;
}

.night-darker {
  color: $night-darker;
}

.night {
  color: $night;
}

.raspberry-darker {
  color: $raspberry-darker;
}

.grape-darker {
  color: $grape-darker;
}

.bg-grass {
  background-color: $grass;
}

.bg-grass-darker {
  background-color: $grass-darker;
}

.calendar__mat-select * {
  color: $night-darker !important;
  font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
}

.nomargin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.nopadding {
  padding: 0 !important;
}

.nopadding .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.filters__input-number-rg {
  mat-radio-button {
    padding: 0px 16px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    color: $night-darker;

    label {
      width: 100%;
    }
  }

  .filters__number-inner {
    padding: 0 20px;
  }

  mat-form-field {
    width: 130px;
    margin: 0 8px;
    height: 50px;
  }
}

.user__menu {
  width: 248px;
  padding: 4px;

  * {
    font-size: 16px !important;
    font-weight: 600;
  }

  &:hover .mat-menu-item:hover * {
    color: $raspberry-darker;
    stroke: $raspberry-darker;
    background-color: none !important;
  }
}

.register__left-panel {
  background-color: $grape-darker;
  background-size: cover;
  background-image: url('/assets/images/signup-bg.png');
  background-position-x: center;
  padding: 80px 0;
}

.overview__section {
  border: 1px solid $smoke;
  border-radius: 4px;
  margin-bottom: 26px;
  padding: 24px;

  h4 {
    font-size: 18px;
    margin-top: 0;
  }
}

.overview__header {
  border-bottom: 1px solid $smoke;
  padding: 24px;

  h4 {
    margin-bottom: 0;
  }
}

.overview__body {
  padding: 24px;
}

.overview__item {
  margin-bottom: 20px;
}

.overview__item:hover {
  cursor: pointer;

  .overview__name {
    color: $raspberry;
  }
}

/*** Start Algolia Places ***/

/* Main input wrapper */
.algolia-places {
  height: 0px !important;
}

/* The algolia-places input */
.ap-input,
.ap-hint {
  height: 18px !important;
  width: 92% !important;
}

/* The dropdown style */
.ap-dropdown-menu {
  top: 22px !important;
}

/* The icons inside the input ( can be a pin or a cross ) */
.ap-input-icon {
  right: -7px !important;
  top: 8px !important;
}

/*** End Algolia Places ***/

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  color: $smoke-darker;
  display: block;
  /* For Firefox */
}

.fullscreen__loader {
  position: absolute;
  width: 100%;
  height: 100%;
}

.task-cal__filter {
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000 !important;
}

.task-cal__select-dropdown {
  height: 300px !important;
  max-height: 300px !important;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: $grass-darker !important;
}

.toggle-group-32 * {
  height: 30px !important;
  line-height: 30px !important;
}

.margin-20 {
  margin: 20px;
}

.overview__effort {
  border: 1px solid $smoke;
  border-radius: 4px;
  padding: 6px;
  background: #fff;
}

.property__link {
  a:hover {
    color: $raspberry-darker;
  }
}

.thread__calendar-card {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 16px;
}

.country-selector {
  left: -20px !important;
}

.ngx-mat-tel-input-container input {
  left: -16px;
  top: -2px;
}

.country-list-button .label-wrapper {
  font-size: 14px;
}

.changelog {
  position: relative;
}

#HW_badge_cont {
  position: absolute !important;
  top: 2px;
  left: 30px;
  width: 20px !important;
  height: 20px !important;
}

#HW_badge {
  background: #ed3c6a;
  color: #fff !important;
  border-radius: 12px !important;
  width: 20px !important;
  height: 20px !important;
  top: 0 !important;
  left: 0 !important;
  padding: 2px 8px;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.register__palantir {
  display: block;
  margin: 40px auto;
}

.page__loading {
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.button__small .mat-button-wrapper a {
  display: block;
  height: 100%;
  line-height: 30px;
}

@media only screen and (min-width: 960px) {
  .mobile__visible,
  .mobile__visible-flex {
    display: none !important;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

ol {
  li {
    margin-bottom: 20px;
    line-height: 26px;
  }
}

.badge {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  height: 20px;
  min-width: 20px;
  background: $raspberry-darker;
  border: 1px solid $raspberry-darker;
  color: #fff !important;
  margin-left: 6px;
  line-height: normal;
}

.badge-space {
  margin: -2px 0 0 -10px;
}

.badge-clear {
  color: $raspberry-darker !important;
  background: #fff;
  border: 1px solid $raspberry-darker;
}

.badge-grass-clear {
  color: darken($grass-darker, 10%) !important;
  background: #fff;
  border: 1px solid $grass-darker;
}

.badge-lemon {
  background: $lemon-darker !important;
  border: 1px solid $lemon-darker;
}

.badge-lemon-clear {
  background: #fff !important;
  border: 1px solid $lemon-darker;
  color: darken($lemon-darker, 20%) !important;
}

.badge-danger {
  background: crimson !important;
  border: 1px solid crimson;
}

.badge-grass {
  background: $grass-darker !important;
  border: 1px solid $grass-darker;
}

.badge-grape {
  background: $grape !important;
  border: 1px solid $grape;
}

.badge-grape-clear {
  background: transparent !important;
  border: 1px solid $grape;
  color: $grape !important;
}

.badge-grape-lighter {
  background: $grape-lighter !important;
  border: 1px solid $grape-lighter;
  color: $night !important;
}

.bg-smoke-lighter {
  background: $smoke-lighter !important;
  color: $night;
}

.readonly .mat-form-field-outline {
  background: $smoke-lighter;
  border-radius: 4px;
}

.raspberry-dot {
  width: 8px;
  height: 8px;
  background: $raspberry-darker;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 4px;
}

.pointer-events {
  pointer-events: none;
}

.push__right {
  margin-left: auto !important;
}

tr.mat-footer-row,
tr.mat-row {
  height: 58px !important;
}

.mat-sort-header-sorted {
  color: $raspberry-darker;
}

.hide__tabs {
  .mat-tab-header {
    display: none;
  }
}

.input__borderless {
  border: 2px solid transparent;
  outline: none;
  background: transparent;
  min-width: 80px;

  &:hover {
    border-bottom: 2px dashed $smoke;
  }

  &:focus {
    border-bottom: 2px solid $grass;
  }
}

.shortcode {
  background: $grass-lighter;
  border: 1px solid $grass-darker;
  border-radius: 4px;
  padding: 4px;
}

.customcode {
  background: $grape-lighter;
  border: 1px solid $grape;
  border-radius: 4px;
  padding: 4px;

  .shortcode {
    background: none;
    border: none;
    padding: 0;
  }
}

.smartcode {
  color: $raspberry-lighter;
  position: relative;
  background: $raspberry-lighter;
  border: 1px solid $raspberry;
  border-radius: 40px;
  padding: 4px 16px 4px 0;

  &::before {
    content: attr(data-codeLabel);
    color: $night;
    position: absolute;
    top: -1px;
    left: 8px;
    white-space: nowrap;
    width: 100%;
    line-height: 30px;
  }

  &::after {
    content: '';
    color: $night;
    background: url('/assets/iconography/magic-night.svg') no-repeat;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 16px;
    height: 16px;
  }
}

.selection__box {
  padding: 16px;
  border: 1px solid $smoke;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
  background: #fff;
  min-width: 120px;

  &:hover {
    border: 1px solid $grass-darker;
    background: $grass-lighter;
  }
}

.selection__box-selected {
  border: 1px solid $grass-darker;
  background: $grass-lighter;
}

.input__editable {
  overflow-x: auto;
}

.mat-checkbox-layout {
  background: #fff;
}

.mat-checkbox:hover {
  .mat-checkbox-layout {
    background: transparent;
  }
}

.properties__merge-container {
  max-width: 500px;
  padding: 16px;
  border-radius: 4px;
  position: sticky;
  background: #fff;
  margin-top: 40px;
  bottom: 40px;
  left: calc(50% - 112px);
}

.linkified {
  color: $raspberry-darker;
}

.slimline {
  margin: 0 !important;
  padding: 0 !important;

  .mat-form-field-wrapper {
    padding: 0 !important;
  }

  .mat-select-placeholder {
    display: inline-block;
  }
}

[hidden] {
  display: none !important;
}

.settings-menu-expanded {
  margin-left: 130px;
  margin-bottom: -30px;
}

.settings-menu-collapsed {
  margin-left: 40px;
}

.user-menu-expanded {
  position: relative;
  left: 240px;
  bottom: -60px;
}

.user-menu-collapsed {
  margin-left: 60px;
  margin-bottom: -20px;
}

body.has__global-notifications {
  #main-app-area {
    padding-top: 32px;
  }
}

.mat-button-wrapper {
  width: 100%;
}

.text__medium {
  font-weight: 600;
}

.bg-airbnb {
  background: #ff5a5f;
}

.bg-vrbo {
  background: #245abc;
}

.bg-booking {
  background: #273b7d;
}

.booking {
  color: #273b7d;
}

.vrbo {
  color: #245abc;
}

body.has__global-notifications .mat-drawer-container {
  margin-top: -32px;
}

.back-button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid rgba(179, 175, 187, 0.5);
  margin-right: 12px;

  &:hover {
    border: 1px solid rgba(237, 60, 106, 0.5);

    svg path {
      stroke: $raspberry-darker;
    }
  }
}

.strikethrough {
  text-decoration: line-through;
}

.form-label {
  font-size: 12px;
  color: $night-darker;
}

.mat-button-wrapper .label {
  vertical-align: unset !important;
}

.prefix-bg {
  display: inline-block;
  background: $smoke-lighter;
  padding: 0 12px;
  padding-right: 0;
  height: 42px;
  line-height: 42px;
  position: relative;
  top: -5px;
  left: -9px;
  color: $night-lighter;
  border-right: 1px solid $smoke;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .mat-slide-toggle {
    transform: scale(0.7);
    position: relative;
    top: 4px;
    left: -10px;
    padding: 0 !important;
  }
}

// Preserve the border on field focus
.mat-focused {
  .prefix-bg {
    height: 40px;
    line-height: 40px;
    left: -8px;
    border-right: 2px solid $grass-darker;
  }
}

.permission-box {
  border: 1px solid $smoke-darker;
  padding: 18px 16px;
  border-radius: 4px;
  margin-top: 12px;
}

.permission-box-disabled {
  background: $smoke-lighter;
}

.permission-box-disabled .mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: $night-lighter;
}

.radio-option {
  border-radius: 4px;
  border: 1px solid $smoke-darker;
  padding: 24px;
  cursor: pointer;
  margin-bottom: 12px;

  &:hover {
    background: $grass-lighter;
    border: 1px solid $grass-darker;
  }

  &.selected {
    border: 1px solid $grass-darker;
    background: $grass-lighter;

    .radio {
      border: 1px solid $grass-darker;

      div {
        width: 14px;
        height: 14px;
        background: $grass-darker;
        border-radius: 50%;
        position: relative;
        top: 4px;
        left: 4px;
      }
    }
  }

  .radio {
    width: 24px;
    height: 24px;
    border: 1px solid $smoke;
    border-radius: 50%;
  }

  > span {
    display: block;
    margin-top: 12px;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 6px;
  }

  span {
    font-size: 14px;
    line-height: 1.6;
  }
}

.coming_soon {
  margin-top: 32px;
  opacity: 0.3;
  background: $smoke-lighter;
  border-radius: 4px;
  padding: 20px;
  padding-top: 0;
  pointer-events: none;
}

.error-snackbar {
  background: $lemon-lighter;
  color: $night;
  border: 1px solid $lemon;

  .mat-simple-snackbar-action {
    color: $white;
    background: $raspberry-darker;
    border-radius: 4px;
  }
}

.success-snackbar {
  background: $grass-lighter;
  color: $night;
  border: 1px solid $grass-darker;

  .mat-simple-snackbar-action {
    color: $white;
    background: $grass-darker;
    border-radius: 4px;
  }
}

.neutral-snackbar {
  background: #fff;
  color: $night;
  border: 1px solid $smoke-darker;
  line-height: 1.6;

  .mat-simple-snackbar-action {
    color: $white;
    background: $grass-darker;
    border-radius: 4px;
  }
}

.inbox__filters-container sbnb-search-bar .mat-form-field-outline,
.properties-filter-container sbnb-search-bar .mat-form-field-outline {
  background: #fff !important;
}

.inbox__filters-container .mat-input-element,
.properties-filter-container .mat-input-element {
  cursor: pointer !important;

  &::placeholder {
    color: $night-lighter;
  }
}

.multiline-mat-option.mat-option {
  white-space: normal;
  line-height: normal;
  height: 72px;
}

.mat-radio-label-content {
  white-space: normal;
}

.app__item-category {
  display: inline-block;
  font-size: 12px;
  color: $grass-darker;
  border: 1px solid $grass-darker;
  border-radius: 2px;
  background: $grass-lighter;
  padding: 2px 8px;
}

body:has(.no-scroll-dialog) {
  overflow: hidden;
}

ul.list {
  padding-left: 12px;
  margin: 0;
  color: $night;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.list__looser {
    gap: 16px;
  }

  li {
    line-height: 1.25rem;
  }
}

.link {
  color: $grass-darker;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.link-emphasized {
  color: $raspberry-darker;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.link-muted {
  color: $night-lighter;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
}

.dropdown-btn {
  border: 1px solid #e5e2e9;
  color: #17141c;
  font-size: 16px;
  border-radius: 4px;
  height: 44px;
  margin: 0.25em 0;
  background: #ffffff;
  padding: 0 16px !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
