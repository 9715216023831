// Our Palette
$night-darker: #17141c;
$night: #5c576a;
$night-lighter: #b3afbb;

$grape-darker: #230b41;
$grape: #9571c3;
$grape-lighter: #f6f0ff;

$raspberry-darker: #ed3c6a;
$raspberry: #f69db4;
$raspberry-lighter: #fce6ec;

$grass-darker: #47c693;
$grass: #afead2;
$grass-lighter: #ebf9f3;

$lemon-darker: #ffc74c;
$lemon: #ffdd6d;
$lemon-lighter: #fff2c6;

$smoke-darker: #d0cdd4;
$smoke: #e5e2e9;
$smoke-lighter: #f8f7f9;

$rose-darker: #d43b3b;
$rose: #e86161;
$rose-lighter: #fadfdf;

$coral: #ff7043;
$aqua: #4fc3f7;
$white: #ffffff;

$airbnb-red: #ff5a5f;
$airbnb-secondary: #00a699;
$vrbo-blue: #245abc;
$booking-blue: #003580;
$tripadvisor-green: #00af87;

/* Monorepo color mappings */
$blue-light: $aqua;
$blue: $aqua;
$blue-dark: $aqua;

// purple-light is in tailwind.config.js
$purple: $grape;
$purple-dark: $grape-darker;

$pink-light: $raspberry-lighter;
$pink: $raspberry;
$pink-dark: $raspberry-darker;

$orange-light: $coral;
$orange: $coral;
$orange-dark: $coral;

$green-light: $grass-lighter;
$green: $grass;
$green-dark: $grass-darker;

$yellow-light: $lemon-lighter;
$yellow: $lemon;
$yellow-dark: $lemon-darker;

$red-light: $rose-lighter;
$red: $rose;
$red-dark: $rose-darker;

$gray-light: $night-lighter;
$gray: $night;
$gray-dark: $night-darker;

$alt-gray-light: $smoke-darker;
$alt-gray: $smoke;
$alt-gray-dark: $smoke-lighter;
/* End Monorepo color mappings */

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
$box-shadow-heavy: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow-heavier: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

// Implementations of palette
$body-bg: #fff;
$primary-bg: $grape-darker;
$subnav-bg: $smoke-lighter;
$primary-text-color: $night;
$text-headings: $grape-darker;
$text-headings-light: $night-lighter;

$error: $lemon-darker;
$error-bg: lighten($lemon-lighter, 7%);

$team-cleaning: #4fc3f7;
$team-checkin: #ab47bc;
$team-maintenance: #ff7043;

// Breakpoints
$breakpoint-mobile: 960px;

// Breakpoint helpers.
$mobile: 'only screen and (max-width: #{$breakpoint-mobile})';
$desktop: 'only screen and (min-width: #{$breakpoint-mobile})';

$textarea-padding: 16px;
$textarea-border-radius: 8px;
